import {useState, useEffect} from 'react'



const App = () => {

  const [value, setValue] = useState("")
  const [message, setMessage] = useState(null)
  const [previousChats, setPreviousChats] = useState([])
  const [currentTitle, setCurrentTitle] = useState(null)
  const [loading, setLoading] = useState(false)

  const createNewChat = () => {
    setMessage(null)
    setValue("")
    setCurrentTitle(null)
  }

  const handleClick = (uniqueTitle) => {
    setCurrentTitle(uniqueTitle)
    setMessage(null)
    setValue("")
  }

  const getMessages = async () => {
    setLoading(true)
    const options = {
      method: "POST",
      body: JSON.stringify({
        message: value
      }),
      headers: {
        "Content-Type": "application/json"
      }
    }
    try {
        const response = await fetch('http://localhost:8000/completions', options)
        const data = await response.json()
        setMessage(data.choices[0].message)
    } catch (error) {
        console.error(error)
    }
    setLoading(false)
    }


    useEffect(() => {
      // console.log(currentTitle, value, message)
      if (!currentTitle && value && message) {
        setCurrentTitle(value)
      }
      if (currentTitle && value && message) {
        setPreviousChats(prevChats => (
          [...prevChats,
            {
              title: currentTitle,
              role: "user",
              content: value
            },
            {
              title: currentTitle,
              role: message.role,
              content: message.content
            }
          ]
        ))
      }
    }, [message, currentTitle])


    // console.log(previousChats)

    const currentChat = previousChats.filter(previousChats => previousChats.title === currentTitle)
    const uniqueTitles = Array.from(new Set(previousChats.map(previousChats => previousChats.title)))

    // console.log(uniqueTitles)

  return (
    <div className="app">

      <section className="side-bar">

        <button onClick={createNewChat}>+ New Chat</button>
        <ul className="history">
          {uniqueTitles?.map((uniqueTitle, index) => <li key={index} onClick={() => handleClick(uniqueTitle)}>{uniqueTitle}</li>)}
        </ul>
        <nav>
          <p><a href="https://gocz.ch/" target="_blank" rel="noreferrer">gocz.ch</a></p>
        </nav>

      </section>



      <section className="main">

        <div className="top-section">
          <div className="liluAvatar"></div>
          <h1>Lilu<br />AI chatbot assistant</h1>
          {!currentTitle && <h3>Hello there</h3>}
          <ul className="feed">
              {currentChat?.map((chatMessage, index) => <li key={index}>
                <p className="role">{chatMessage.role}</p>
                <p>{chatMessage.content}</p>
              </li>)}
          </ul>
        </div>

        <div className="bottom-section">
          <div className="input-container">
            <input value={value} onChange={(e) => setValue(e.target.value)}/>
            <div id="submit" onClick={getMessages}>
              {loading ? (<svg className="spinner" viewBox="0 0 50 50">
              <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
              </svg>)
              :
              ("➢")}
            </div>
          </div>

          <p className="info">
            This page developed by <a href="https://gocz.ch/" target="_blank" rel="noreferrer">Bela Gocz</a> with technologies of HTML, CSS, JavaScript, JSON, ReactJS, NodeJS and OpenAI-API.<br />
            The Lilu AI ChatBot uses the engine of <a href="https://platform.openai.com/docs/guides/chat" target="_blank" rel="noreferrer">OpenAI Chat GPT version_3.5-turbo</a>.
          </p>

          <p className="mobileInfo">
            You are using a mobile device now. For the all services of this app visit here again with your tablet or PC.
          </p>

        </div>

      </section>
    </div>
  )
}

export default App
